//animation keyframe
@keyframes hero-thumb-sm-animation {
	0% {
		-webkit-transform: translateY(-20px) translateX(50px);
		-moz-transform: translateY(-20px) translateX(50px);
		-ms-transform: translateY(-20px) translateX(50px);
		transform: translateY(-20px) translateX(50px);
	}
	100% {
		-webkit-transform: translateY(-20px) translateX(0px);
		-moz-transform: translateY(-20px) translateX(0px);
		-ms-transform: translateY(-20px) translateX(0px);
		transform: translateY(-20px) translateX(0px);
	}
}
@keyframes hero-thumb-sm-2-animation {
	0% {
		-webkit-transform: translateY(-50px);
		-moz-transform: translateY(-50px);
		-ms-transform: translateY(-50px);
		transform: translateY(-50px);
	}
	100% {
		-webkit-transform: translateY(0px);
		-moz-transform: translateY(0px);
		-ms-transform: translateY(0px);
		transform: translateY(0px);
	}
}
@keyframes hero-circle-2 {
	0% {
		-webkit-transform: translateX(-200px);
		-moz-transform: translateX(-200px);
		-ms-transform: translateX(-200px);
		transform: translateX(-200px);
	}
	100% {
		-webkit-transform: translateX(0px);
		-moz-transform: translateX(0px);
		-ms-transform: translateX(0px);
		transform: translateX(0px);
	}
}

@keyframes hero-bounce {
	0%,
	20%,
	50%,
	80%,
	100% {
		transform: translateY(0);
	}
	40% {
		transform: translateY(-30px);
	}
	60% {
		transform: translateY(-15px);
	}
}

@keyframes hero-circle-1 {
	0% {
		@include transform(rotate(0deg));
	}
	100% {
		@include transform(rotate(360deg));
	}
}
@keyframes hero-triangle-1 {
	0% {
		-webkit-transform: rotate(0deg);
		-moz-transform: rotate(0deg);
		-ms-transform: rotate(0deg);
		transform: rotate(0deg);
	}
	100% {
		-webkit-transform: rotate(360deg);
		-moz-transform: rotate(360deg);
		-ms-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}
@keyframes services-triangle {
	0% {
		-webkit-transform: rotate(0deg) translateX(-50px);
		-moz-transform: rotate(0deg) translateX(-50px);
		-ms-transform: rotate(0deg) translateX(-50px);
		transform: rotate(0deg) translateX(-50px);
	}
	100% {
		-webkit-transform: rotate(360deg) translateY(100px);
		-moz-transform: rotate(360deg) translateY(100px);
		-ms-transform: rotate(360deg) translateY(100px);
		transform: rotate(360deg) translateY(100px);
	}
}
