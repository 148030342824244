a,
.btn,
button,
span,
p,
i,
input,
select,
textarea,
li,
img,
svg path,
*::after,
*::before,
.transition-3,
h1,
h2,
h3,
h4,
h5,
h6 {
	@include transition(0.3s);
}
a:focus,
.button:focus {
	text-decoration: none;
	outline: none;
}
a:focus,
a:hover {
	color: inherit;
	text-decoration: none;
}
a,
button {
	color: inherit;
	outline: none;
	border: none;
	background: transparent;
}
button:hover {
	cursor: pointer;
}
button:focus {
	outline: 0;
}
.uppercase {
	text-transform: uppercase;
}
.capitalize {
	text-transform: capitalize;
}
h1,
h2,
h3,
h4,
h5,
h6 {
	font-family: $gotamnarrow;
	// color: $heading-color;
	margin-top: 0px;
	
	line-height: 1.3;
	@include transition(0.3s);
}

h1 {
	font-size: 40px;
}
h2 {
	font-size: 36px;
}
h3 {
	font-size: 24px;
}
h4 {
	font-size: 20px;
}
h5 {
	font-size: 16px;
}
h6 {
	font-size: 14px;
}
ul {
	margin: 0px;
	padding: 0px;
}
p {
	font-family: $gotamnarrowlight;
	font-size: 20px;
	font-weight: normal;
	margin-bottom: 15px;
	line-height: 26px;
}

*::-moz-selection {
	background: $color-primary;
	color: $white;
	text-shadow: none;
}
::-moz-selection {
	background: $color-primary;
	color: $white;
	text-shadow: none;
}
::selection {
	background: $color-primary;
	color: $white;
	text-shadow: none;
}

/*--
    - Input Placeholder
-----------------------------------------*/
*::-moz-placeholder {
	color: $body-text;
	font-size: 14px;
	opacity: 1;
}
*::placeholder {
	color: $body-text;
	font-size: 14px;
	opacity: 1;
}

/*--
    - Common Classes
-----------------------------------------*/
.fix {
	overflow: hidden;
}
.clear {
	clear: both;
}

.f-left {
	float: left;
}
.f-right {
	float: right;
}

.z-index-1 {
	z-index: 1;
}

.z-index-11 {
	z-index: 11;
}

.overflow-y-visible {
	overflow-x: hidden;
	overflow-y: visible;
}

.p-relative {
	position: relative;
}
.p-absolute {
	position: absolute;
}
/*--
    - Background color
-----------------------------------------*/
.grey-bg {
	background: $theme-color-2;
}
.section-bg {
	background: $section-bg;
}

.pink-bg {
	background: $theme-color;
}

.white-bg {
	background: $white;
}
.black-bg {
	background: $black;
}

.footer-bg {
	background: $footer-bg;
}

// .primary-bg {
// 	background: #222;
// }

/*--
    - color
-----------------------------------------*/

.text-white h1,
.text-white h2,
.text-white h3,
.text-white h4,
.text-white h5,
.text-white h6,
.text-white p,
.text-white span,
.text-white li,
.text-white a {
	color: $white !important;
}

.white-color {
	color: $white;
}

.theme-color {
	color: $theme-color !important;
}
.black-color {
	color: $black;
}
.edu-border-btn {
	display: inline-block;
	height: 55px;
	line-height: 55px;
	text-align: center;
	font-size: 16px;
	
	color: $theme-color;
	background-color: transparent;
	padding: 0 30px;
	border: 1px solid #dce8fe;
	@include border-radius(4px);
	@include transition(0.3s);
	&:hover {
		background-color: $theme-color;
		color: $white;
		border-color: $theme-color;
	}
	&:focus {
		border: 1px solid #dce8fe;
	}
}
