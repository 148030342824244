$white: #ffffff;
$black: #000;
$theme-color-2:#000;
$theme-color-2-2:#A2A2A2;
$footer-bg: #000;
$theme-color: #eeb10e;
$color-primary: #D1AD4F;
$theme-bg: #F6F8FB;
$body-text: #475467;
$border:#edeef2;
$heading-color: #101828;
$section-bg: #F6F8FB;
$gotamnarrow: 'gotam-narrow';
$gotamnarrowbold: 'gotam-narrow-bold';
$gotamnarrowlight: 'gotam-narrow-light';
$nunito: 'Nunito Sans', sans-serif;
$raleway: 'Raleway', sans-serif;
$fontawesome: "Font Awesome 5 Pro";

@mixin background($position: center,$size: cover,$repeat: no-repeat) {
  background: {
      position: $position;
      repeat: $repeat;
      size: $size;
  }
}
@mixin transition($time) {
  -webkit-transition: all $time ease-out 0s;
  -moz-transition: all $time ease-out 0s;
  -ms-transition: all $time ease-out 0s;
  -o-transition: all $time ease-out 0s;
  transition: all $time ease-out 0s;
}
@mixin transform($transforms) {
  -webkit-transform: $transforms;
  -moz-transform: $transforms;
  -ms-transform: $transforms;
  transform: $transforms;
}
@mixin border-radius($man) {
-webkit-border-radius: $man;
-moz-border-radius: $man;
border-radius: $man;
}
@mixin sentence-case() {
  text-transform: lowercase;
  &:first-letter {
      text-transform: uppercase;
  }
}
@mixin flexbox() {
display: -webkit-box;
display: -moz-box;
display: -ms-flexbox;
display: -webkit-flex;
display: flex;
}
@mixin box-shadow($shadow) {
-webkit-box-shadow: $shadow;
  -moz-box-shadow: $shadow;
      box-shadow: $shadow;
}
$xxxl: 'only screen and (min-width: 1600px) and (max-width: 1800px)';
$xxl: 'only screen and (min-width: 1400px) and (max-width: 1599px)';
$xl: 'only screen and (min-width: 1200px) and (max-width: 1399px)';
$lg : 'only screen and (min-width: 992px) and (max-width: 1199px)';
$md:'only screen and (min-width: 768px) and (max-width: 991px)';
$sm: 'only screen and (min-width: 576px) and (max-width: 767px)';
$xs:'(max-width: 575px)';